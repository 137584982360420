<template>
  <div>
    <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth"></HeaderNav>
    <div class="test">
      <SideBarDesktop v-if="$store.state.isNavBarOff" />

      <div class="wrapper-lc">
        <div class="container">
          <div class="alert-wrapper" v-if="!this.$store.state.isActivetedUser">
            <div class="alertActiveAccount">
              Ваш email не подтвержден, проверьте ваш email
              {{ this.$store.state.email }}
            </div>
            <div class="container-btnLink">
              <span class="forgotPassword" @click="$router.push({ name: 'approve-email' }).catch(() => {})">Подтвердить email</span>
            </div>
          </div>

          <div class="notification-title">Уведомления</div>
          <NotificationList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AlertActiveAccount from "@/components/AlertActiveAccount.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import NotificationList from "@/components/NotificationList.vue";
import SideBarDesktop from "@/components/SideBarDesktop.vue";

export default {
  components: { HeaderNav, NotificationList, SideBarDesktop },
};
</script>

<style lang="scss" scoped>
.notification-title {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.005em;

  color: #1a1a1a;
  margin-bottom: 32px;
}

.alert-wrapper {
  margin-bottom: 32px;
}
.alertActiveAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 60px;
  background: #fcd3d3;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--main-color);
}

.forgotPassword {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: var(--main-color);
  cursor: pointer;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .container {
    padding: 60px;
  }

  .notification-title {
    font: 700 40px "PT Sans";
  }
}
</style>
