<template>
  <div class="v-form v-form__downl">
    <div class="outerUpload">
      <div class="alert-wrapper" v-if="!this.$store.state.isActivetedUser">
        <div class="alertActiveAccount">Ваш email не подтвержден, проверьте ваш email {{ this.$store.state.email }}</div>
        <div class="container-btnLink">
          <span class="forgotPassword" @click="$router.push({ name: 'approve-email' }).catch(() => {})">Подтвердить email</span>
        </div>
      </div>

      <h1 class="upload__title">Выберите вариант загрузки чека</h1>

      <v-alert :value="isError" elevation="2" dense type="error" dark transition="scale-transition">{{ errorMessage }}</v-alert>

      <div class="accept__btns">
        <div>
          <div id="reader" />
          <input type="file" id="qr" accept=".png,.jpg,.jpeg" ref="uploader" @change="onFileChanged" />
          <v-btn depressed color="primary" class="next-btn" block :loading="isLoading" @click="handleFileImport">
            <div class="btn-content-container">Загрузить фото чека</div></v-btn
          >
        </div>

        <div>
          <v-btn depressed color="primary" class="next-btn" block @click="handleQrScan"
            ><div class="btn-content-container">Отсканировать QR-код</div></v-btn
          >
        </div>

        <div>
          <v-btn depressed color="primary" class="next-btn" block @click="handleManualUpload"
            ><div class="btn-content-container">Ввести вручную</div></v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AlertActiveAccount from "@/components/AlertActiveAccount";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";
import { Html5Qrcode } from "html5-qrcode";

export default {
  data() {
    return {
      isLoading: false,
      user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    handleQrScan() {
      this.$router.push({ name: "LcScan" }).catch(() => {});
      this.$reachGoal("upload_check");
    },
    handleFileImport() {
      this.$refs.uploader.click();
      this.$reachGoal("upload_check");
    },
    handleManualUpload() {
      this.$router.push({ name: "LcManual" }).catch(() => {});
      this.$reachGoal("upload_check");
    },

    async onFileChanged(e) {
      try {
        const formData = new FormData();
        const qrData = await this.scanPhoto(e.target.files[0]);

        if (!qrData) throw new Error("Некорректный QR-код");

        formData.append("file", e.target.files[0]);
        formData.append("accountId", this.user_id);
        formData.append("qrcodeData", qrData);

        this.isLoading = true;
        this.isError = false;

        const { data } = await axios.post(`${process.env.VUE_APP_QR}/save-check-photo`, formData);

        if (data === "qrcode already exist") {
          this.errorMessage = `Данный чек уже участвует в акции`;
          this.isError = true;
        } else {
          this.$store.state.isActivetedUser
            ? this.$router.push({ name: "LcSuccess" }).catch(() => {})
            : this.$router.push({ name: "first_check" }).catch(() => {});
        }
      } catch (error) {
        this.isError = true;
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async scanPhoto(image) {
      try {
        const html5QrCode = new Html5Qrcode("reader");
        const qrData = await html5QrCode.scanFile(image, false);
        const kv = qrData.split("&");
        const res = {};

        for (let i = 0; i < kv.length; i++) {
          let tmp = kv[i].split("=");
          res[tmp[0]] = tmp[1];
        }

        if (!res.fn || !res.fp || !res.i || !res.s) return null;

        return JSON.stringify({
          fn: res.fn,
          fp: res.fp,
          fd: res.i,
          summary: res.s,
          time: res.t,
          operation: res.n,
        });
      } catch (error) {
        return null;
      }
    },

    decodeStr(str) {
      try {
        let kv = str.split("&");
        let res = {};
        for (let i = 0; i < kv.length; i++) {
          let tmp = kv[i].split("=");
          res[tmp[0]] = tmp[1];
        }
        return res;
      } catch (e) {
        console.error(e.message);
        return false;
      }
    },

    ...mapActions({
      getUserNotification: "getUserNotification",
    }),
  },
  watch: {
    errorMessage() {},
  },
};
</script>

<style scoped>
.btn-content-container {
  color: var(--text-color);
}

.v-form.v-form__downl {
  gap: 24px;
  min-height: 200px !important;
}

.upload__title {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.005em;
  color: #1a1a1a;
  margin-bottom: 18px;
}

.upload__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #1a1a1a;
  margin-bottom: 28px !important;
}

.accept__btns {
  gap: 0 !important;
}

.next-btn.btn__download.accept__btn {
  margin-bottom: 4px;
}

.alert-wrapper {
  margin-bottom: 32px;
}
.alertActiveAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 60px;
  background: #fcd3d3;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--main-color);
}

.forgotPassword {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: var(--main-color);
  cursor: pointer;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .v-form {
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    padding: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .outerUpload {
    max-width: 400px;
  }

  .upload__title {
    font: 700 40px "PT Sans";
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
  }
}
</style>
