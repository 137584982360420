<template>
  <v-app>
    <div id="app" v-if="!isLoading">
      <Sidebar />
      <router-view />
    </div>
    <div v-else class="loader-container">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </v-app>
</template>

<script>
import "./style.css";

import { mapActions } from "vuex";

import Sidebar from "./components/Sidebar-menu.vue";

const metaDomain = "https://checkngo.ru";
const shareMediaImagePath = "og_image.jpg";

export default {
  components: { Sidebar },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getUserData: "getUserData",
      getQrcodes: "getQrcodes",
      getUserNotification: "getUserNotification",
      onResize: "onResize",
      getPromoConfig: "getPromoConfig",
    }),
    async initializeApp() {
      try {
        await this.getPromoConfig();
      } finally {
        this.isLoading = false;
      }
    },
  },
  metaInfo() {
    const config = this.$store.state.promoConfig;
    const metaTitle = config?.seo?.metaTitle || "Акция – Регистрируй чек и выигрывай!";
    const metaDescription = config?.seo?.metaDescription || "Регистрируйте чеки для участия в акции и выигрывайте крутые призы. Главный приз – автомобиль!";
    return {
      title: metaTitle,
      titleTemplate: `%s | Check'n'Go`,
      meta: [
        { name: "description", content: metaDescription },
        // open graph
        { name: "og:url", content: metaDomain},
        { name: "og:type", content: "website" },
        { name: "og:title", content: metaTitle },
        { name: "og:description", content: metaDescription },
        { name: "og:image", content: shareMediaImagePath },
        // twitter
        { name: "twitter:card", content: shareMediaImagePath },
        { name: "twitter:image", content: shareMediaImagePath },
        { property: "twitter:domain", content: metaDomain },
        { property: "twitter:url", content: metaDomain },
        { name: "twitter:title", content: metaTitle },
        { name: "twitter:description", content: metaDescription },
        // apple
        { name: "apple-mobile-web-app-title", content: metaTitle },
        // windows
        { name: "msapplication-TileImage", content: config?.seo?.msapplicationTileImage || 'https://static.tildacdn.com/tild3261-6266-4437-b735-343264353932/_1_1_1.png'},
        { name: "msapplication-TileColor", content: config?.seo?.msapplicationTileColor || '#000000' },
      ],
      link: [
        { rel: "shortcut icon favicon", href: config?.seo?.faviconLink || 'https://static.tildacdn.com/tild6561-6566-4166-b037-393637333738/favicon.ico' },
        { rel: "apple-touch-icon", sizes: "180x180", href: config?.seo?.appleTouchIcon || 'https://static.tildacdn.com/tild3764-6130-4334-b364-303364656365/_1_2_1_1.png' },
        { rel: "apple-touch-icon", sizes: "76x76", href: config?.seo?.appleTouchIcon76 },
        { rel: "apple-touch-icon", sizes: "152x152", href: config?.seo?.appleTouchIcon152 },
        { rel: "apple-touch-startup-image", href: config?.seo?.appleTouchStartupImage || 'https://static.tildacdn.com/tild3764-6130-4334-b364-303364656365/_1_2_1_1.png' },
      ],
      style: [
        {
          cssText: `
          :root {
            --main-color: ${config?.design?.buttonPrimaryColor || '#89B924'};
            --second-color: ${config?.design?.buttonSecondaryColor || '#555555'};
            --background-color: ${config?.design?.backgroundColor || 'linear-gradient(45deg, #b6cee9, #949ae7)'};
            --block-color: ${config?.design?.textBackgroundColor || '#ffffff'};
            --text-color: ${config?.design?.textColor || '#000000'};
          }
          #app {
            font-family: ${config?.design?.fontFamily || 'Arial, sans-serif'};
          }
        `,
          type: "text/css",
        },
      ],
      script: [
        // Yandex.Metrika counter - enable for real promo
        {
          async: true,
          defer: true,
          vmid: "extscript",
          type: "text/javascript",
          innerHTML: `
                if (${config?.technical?.yandexMetrikaCode}) {
                  (function (m, e, t, r, i, k, a) {
                  console.log('metrika ${config?.technical?.yandexMetrikaCode}');
                  
                  m[i] =
                    m[i] ||
                    function () {
                      (m[i].a = m[i].a || []).push(arguments);
                    };
                  m[i].l = 1 * new Date();
                  for (var j = 0; j < document.scripts.length; j++) {
                    if (document.scripts[j].src === r) {
                      return;
                    }
                  }
                  (k = e.createElement(t)),
                    (a = e.getElementsByTagName(t)[0]),
                    (k.async = 1),
                    (k.src = r),
                    a.parentNode.insertBefore(k, a);
                })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                ym(${this.$store.state.technical?.yandexMetricaCode}, "init", {
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                });  
              }
            `,
        },
      ],
      noscript: [
        {
          innerHTML: `
              <div>
                <img src="https://mc.yandex.ru/watch/${this.$store.state.technical?.yandexMetricaCode}" style="position: absolute; left: -9999px" alt="" />
              </div>
            `,
        },
      ],
    };
  },
  async created() {
    await this.initializeApp();
  },
  mounted() {
    this.getUserData();
    this.getQrcodes();
    this.getUserNotification();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
